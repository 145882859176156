;(function($, window, document, undefined) {
  var $win = $(window);
  var $doc = $(document);

  function fixedHeader() {
    var winO = $win.scrollTop();
    var $header = $('.header');

    var isHeaderFixed = winO > 0;

    $header.toggleClass('fixed', isHeaderFixed);
  }

  function stickySocials() {
    if ($('.section-podcast .socials-tertiary').length) {
      var winO = $win.scrollTop();
      var headerH = $('.header').outerHeight();
      var $socials = $('.section-podcast .socials-tertiary');
      var sectionOffset = $('.section-podcast').offset().top;

      if (winO > sectionOffset - headerH * 3) {
        $socials.addClass('sticky');
      } else {
        $socials.removeClass('sticky');
      }
    }
  }

  $doc.ready(function() {
    // Fullsize image
    $('.fullsize-image').each(function() {
      var $holder = $(this);
      var imageSrc = $holder.find('img').attr('src');

      $holder.css('background-image', 'url("' + imageSrc + '")');
    });

    // Mobile Navigation
    $('.nav-mobile').on('click', function(e) {
      e.preventDefault();

      $(this).toggleClass('active');

      $('.header').toggleClass('expanded');
    });

    // Initialize Popup
    $('.btn-popup').magnificPopup({
      mainClass: 'popup-dark mfp-fade',
      fixedContentPos: true
    });

    $('.video-replace').click(function(e) {
      e.preventDefault();
      $(this).hide();
      $('.video-after').show();
      $('.video-after video')[0].play();
    });

    $('.awards .expandable').hide();

    $('.section-awards .btn-expand').on('click', function(e) {
      e.preventDefault();

      $(this).toggleClass('active');

      $('.awards .expandable').slideToggle();
    });

    $('.mark-lb').click(function (e) {
      e.preventDefault();
      $('.mfp-close').click();
      MktoForms2.loadForm("//app-sj03.marketo.com", "299-KII-331", 2041, function (form) {
        MktoForms2.lightbox(form).show();
      });
    });
/*
    // Free Book Popup form
    if(!$.cookie('visited')){

      $.magnificPopup.open({
        items: {
          src: '#freebook'
        },
        type: 'inline'
      });

      $.cookie('visited', 'yes', {expires: 1});
    } else {
      console.log('has been visited');
    }

    $('.customx').on( "click", function() {
      $.magnificPopup.close();
    });
*/

      //gf ajax
    $('.gf-ajax').each(function(){
      var $this = $(this);
      var validator = $this.validate({
        submitHandler: function(form){
          $.post(ajax_url, $(form).serialize(), function(result){
            if(result.is_valid){
              $this.replaceWith(result.confirmation_message);
            } else {
              var errors = {};

              $.each(result.validation_messages, function(key, value){
                errors['input_' + key] = value;
              });

              validator.showErrors(errors);
            }
          }, 'json');
        }
      });
    });

    $('.equalizer').imagesLoaded( function() {
      $('.equalizer').equalizer({
        // find divs that are the immediate children of the wrapper
        columns : '> div'
      });
    });
  });

  $win.on('load', function() {
    // Slider
    $('.slider-testimonials .slides').owlCarousel({
      autoplay: true,
      items: 1,
      nav: true,
      loop: true,
      smartSpeed: 1000,
      responsiveRefreshRate: 1,
      autoplaySpeed:1000,
      autoplayTimeout: 9000,
      responsive:{
        0:{
          autoHeight: true
        },
        768:{
          autoHeight: false
        }
      }
    });

    $('.intro-slider').owlCarousel({
      autoplay: true,
      items: 1,
      nav: true,
      loop: true,
      smartSpeed: 1000,
      responsiveRefreshRate: 1,
      autoplaySpeed:1000,
      autoplayTimeout: 9000
    });

  }).on('load scroll', function() {
    fixedHeader();

    stickySocials();
  });
})(jQuery, window, document);
